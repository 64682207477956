@import url(https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@100;700&display=swap);
html,
body {
  min-height: 100vh;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'JetBrains Mono';
  background-color: #000000;
  color: white;
  font-weight: 100;
}

a {
  text-decoration: none;
}

.container:hover ~ .background-layer {
  opacity: 1;
}

.background-layer {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -1;
  position: fixed;
  transition: opacity 1s;
  background: rgba(0, 0, 0, 0.3);
  opacity: 1;
}

.background {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -1;
  position: fixed;
  transition: opacity 3s ease-in-out;
  opacity: 0;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  filter: alpha(opacity=0);
}

@media only screen and (max-device-width: 480px) {
  .background {
    height: 39vh;
  }
}

.background.fade-in {
  opacity: 1;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  filter: alpha(opacity=1);
}

.background.one {
  background: url(/static/media/IMG_2994.e93ffe65.jpg);
  background-size: cover;
  background-position: center center;
}
.background.two {
  background: url(/static/media/IMG_3012.1ba57236.jpg);
  background-size: cover;
  background-position: center center;
}
.background.three {
  background: url(/static/media/IMG_3025.4765ee04.jpg);
  background-size: cover;
  background-position: center center;
}
.background.four {
  background: url(/static/media/IMG_3030.56a0d724.jpg);
  background-size: cover;
  background-position: center center;
}
.background.five {
  background: url(/static/media/IMG_3084.134e20e2.jpg);
  background-size: cover;
  background-position: center center;
}
.background.six {
  background: url(/static/media/IMG_3146.0bd1e777.jpg);
  background-size: cover;
  background-position: center center;
}
.background.seven {
  background: url(/static/media/IMG_3246.acac9923.jpg);
  background-size: cover;
  background-position: center center;
}

.container {
  margin: auto;
  max-width: 800px;
  padding: 1em;
}

@media only screen and (max-device-width: 480px) {
  .header {
    margin-top: 0;
  }
}

.list {
  margin-top: 4em;
  margin-left: 0;
  padding: 1em;
}

.list-item {
  margin-bottom: 0.25em;
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: absolute;
  left: 10%;
  top: 50%;
  transform: translate(0, -50%);
}

.logo {
  margin-bottom: 5rem;
}

@media only screen and (max-device-width: 480px) {
  .logo-container {
    flex-direction: column;
    align-items: flex-start;
  }
}

